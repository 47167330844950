// Event Names
export const CONTENT_LIBRARY = 'Content Library';
export const DASHBOARD = 'Dashboard';
export const EVENT_DETAILS = 'Event Details';
export const FEATURE_LOCK = 'Feature Locked';
export const IPD = 'IPD';
export const MAIN_MENU = 'Main Menu';
export const NAVIGATION = 'Navigation';
export const NOTIFICATION = 'Notification';
export const ONBOARDING = 'Onboarding';
export const PROFILE_MENU = 'Profile Menu';
export const SCHEDULE_WEBINAR = 'Schedule Webinar';
export const SIMULIVE_WEBINAR = 'Simulive Webinar';
export const SEARCH = 'Search';
export const SEARCHV2 = 'SearchV2';
export const SURVEY = 'Survey';
export const TRANSCRIPT = 'Transcript';
export const UPGRADE_URL_CLICKED = 'Upgrade';
export const VIDEO = 'Video';
export const VIDEO_EDIT = 'Video Edit';
export const G2W_SHARE_RECORDING_ORG = 'G2W Share Recording Org';
export const WEBINAR_SUMMARY = 'Webinar Summary';

// Events for new experience/classic accordion view
export const EXPERIENCE_SELECTED = 'Experience Selected';
export const JOIN_OPTION = 'JoinOption';
export const CLASSIC_ACCORDION_VIEWED = 'Classic Accordion Viewed';
export const ME_ACCORDION_VIEWED = 'ME Accordion Viewed';
export const ONBOARDING_GUIDE_VIEWED = 'Onboarding Guide Viewed';
export const VIDEO_GUIDE_VIEWED = 'Video Guide Viewed';

// Types
export const ANALYTICS = 'Analytics';
export const AUDIOSETTINGS = 'Audio Settings';
export const AUTORECORD = 'Auto Record';
export const BRANDING_AND_COLORS = 'Branding and Colors';
export const CALENDAR = 'Calendar';
export const CHANNEL = 'Channel';
export const COPY_PAST_WEBINAR = 'Copy Past Webinar';
export const EMAIL = 'Email';
export const ENGAGEMENT = 'Engagement';
export const NOTIFICATION_PREFERENCES = 'Notification Preferences';
export const ORGANIZERS = 'Organizers';
export const PAGE_VISIT = 'Page Visit';
export const PANELISTS = 'Panelists';
export const REGISTRATION = 'Registration';
export const TABS = 'Tabs';
export const TRIALER_CARD = 'Trialer Card';
export const UPGRADE_CARD = 'Upgrade Card';
export const WEBINAR = 'Webinar';
export const SHARE = 'Share';
export const PAYMENT = 'Payment';
export const PAYMENT_TOS = 'Payment-Tos';
export const PAYMENT_CONNECT_TO_STRIPE = 'Payment-Connect to Stripe';
export const LIVE_STREAMING = 'Live Streaming';

// VirtualEvents
export const SCHEDULING_VIRTUAL_EVENT = 'Scheduling Virtual Event';
export const SCHEDULE_VIRTUAL_EVENT = 'Schedule Virtual Event';
export const VIRTUAL_EVENT_CREATED = 'Virtual Event Created';
export const VIRTUAL_EVENT = 'Virtual Event';
export const VIRTUAL_EVENT_DETAILS = 'Virtual Event Details';
export const VIRTUAL_EVENT_TITLE_UPDATE = 'Virtual Event Title Update';
export const VIRTUAL_EVENT_DATES_UPDATE = 'Virtual Event Dates Update';
export const VIRTUAL_EVENT_LOCALE_UPDATE = 'Virtual Event Locale Update';
export const VIRTUAL_EVENT_ADD_TO_MY_CALENDER = 'Virtual Event Add To My Calender';
export const VIRTUAL_EVENT_ON_CLICK_ORGANIZERS = 'Virtual Event On Organizers Clicked';
export const VIRTUAL_EVENT_ON_ADD_ORGANIZER = 'Virtual Event Organizer Add';
export const VIRTUAL_EVENT_ON_DELETE_ORGANIZER = 'Virtual Event Organizer Remove';
export const VIRTUAL_EVENT_ON_UPDATE_ORGANIZER = 'Virtual Event Organizer Update';

export const VIRTUAL_EVENT_ON_CLICK_PANELISTS = 'Virtual Event On Organizers Clicked';
export const VIRTUAL_EVENT_ON_ADD_PANELIST = 'Virtual Event Panelist Add';
export const VIRTUAL_EVENT_ON_DELETE_PANELIST = 'Virtual Event Panelist Remove';
export const VIRTUAL_EVENT_ON_UPDATE_PANELIST = 'Virtual Event Panelist Update';

export const VIRTUAL_EVENT_ON_ADD_ORGANIZER_AS_SPEAKER = 'Virtual Event Organizer as Speaker Add';
export const VIRTUAL_EVENT_ON_REMOVE_ORGANIZER_AS_SPEAKER = 'Virtual Event Organizer as Speaker Remove';

export const VIRTUAL_EVENT_ON_COMPANY_INFO_CHANGED = 'Virtual Event On Comopany Info Changed';
export const VIRTUAL_EVENT_ON_COMPANY_SOCIAL_MEDIA_CHANGED = 'Virtual Event On Comopany Social Media Changed';

export const VIRTUAL_EVENT_ON_NO_OF_SPEAKERS_CHANGED = 'No Of Speakers';
export const VIRTUAL_EVENT_ON_NO_OF_ORGANIZERS_CHANGED = 'No Of Organizers';
export const VIRTUAL_EVENT_ON_NO_OF_PANELISTS_CHANGED = 'No Of Panelists';

export const SETTINGS = 'Settings';
export const CHROMA_CAM = 'ChromaCam';
export const THEME = 'Theme';

// landing on page
export const LANDING_ON_PAGE = 'Landing On Page';

export const VIRTUAL_EVENT_ON_REGISTRATION_SECTION_COLLAPSE_STATUS = 'Virtual Event Registration Section Collapsed Status';
export const VIRTUAL_EVENT_ON_EDIT_ABOUT_EVENT = 'Edit Virtual Event Description';
export const ON_EDIT_VIRTUAL_EVENT_DETAILS_API_STATUS = 'Update Virtual Event Details Api Status';
export const VIRTUAL_EVENT_ON_EDIT_IMAGE = 'Edit Virtual Event Image';
export const VIRTUAL_EVENT_ON_OPEN_IMAGE_PICKER = 'Edit Virtual Event Image';
export const VIRTUAL_EVENT_ON_DELETE_IMAGE = 'Delete Virtual Event Image';

// engagement details
export const SURVEY_ADDED_TO_SESSION = 'Survey Added to Session';
export const SURVEY_DELETED_FROM_SESSION = 'Survey Deleted from Session';
export const ON_SURVEY_CREATED_API_STATUS = 'Create Survey Api Status';
export const ON_SURVEY_DELETED_API_STATUS = 'Delete Survey Api Status';

export const POLL_ADDED_TO_SESSION = 'Poll Added to Session';
export const POLL_DELETED_FROM_SESSION = 'Poll Deleted from Session';
export const ON_POLL_CREATED_API_STATUS = 'Create Poll Api Status';
export const ON_POLL_DELETED_API_STATUS = 'Delete Poll Api Status';

export const HANDOUT_ADDED_TO_SESSION = 'Handout Added to Session';
export const HANDOUT_DELETED_FROM_SESSION = 'Handout Deleted from Session';
export const ON_HANDOUT_CREATED_API_STATUS = 'Create Handout Api Status';
export const ON_HANDOUT_DELETED_API_STATUS = 'Delete Handout Api Status';

export const VIDEO_UPLOAD_TO_SESSION = 'Video Upload to Session';
export const VIDEO_DELETED_FROM_SESSION = 'Video Deleted from Session';
export const YOUTUBE_LINK_UPLOAD_TO_SESSION = 'YouTube Link Added to Session';
export const ON_VIDEO_UPLOADED_API_STATUS = 'Create Video Api Status';
export const ON_VIDEO_DELETED_API_STATUS = 'Delete Video Api Status';
export const ON_YOUTUBE_LINK_UPLOADED_API_STATUS = 'Create Video Api Status';
export const ON_YOUTUBE_LINK_DELETED_API_STATUS = 'Delete Video Api Status';

// virtual event registration question
export const VIRTUAL_EVENT_ON_EDIT_REGISTRATION_QUESTION = 'Virtual Event Edit Registration Questions';
export const VIRTUAL_EVENT_ON_SAVE_REGISTRATION_QUESTION = 'Virtual Event Save Registration Questions';
export const VIRTUAL_EVENT_ON_SAVE_REGISTRATION_QUESTION_API_STATUS = 'Virtual Event Save Registration Questions Api Status';
export const VIRTUAL_EVENT_ON_ADD_CUSTOM_QUESTION = 'Virtual Event Add custom Question';
// VE Tab select event on home page
export const VE_TAB_CHANGE_ON_HOME_PAGE = 'Change Of Tab On Home Page';
// copy event
export const COPY_VIRTUAL_EVENT_ON_VE_DETAILS_PAGE = 'Copy Virtual Event On VE Details Page';
export const COPY_VIRTUAL_EVENT_ON_VE_LIST_PAGE = 'Copy Virtual Event On VE List Page';
export const COPY_VIRTUAL_EVENT_ON_VE_SCHEDULE_PAGE = 'Copy Virtual Event On VE Schedule Page';
export const COPY_VE_ON_SELECT_OF_EVENT_TYPE = 'Experience Type Selected On Copy VE Schedule Page';
export const COPY_VE_ON_SELECT_OF_EVENT = 'Event Selected On Copy VE Schedule Page';

export const VIRTUAL_EVENT_ON_UPDATE_APPROVAL_TYPE = 'Manual approval in VE selected';
// Virtual Event Session Live Streaming Tracker
export const CONFIGURE_LIVE_STREAM_IN_VE = 'Configure Live Stream in VE';
export const EDIT_LIVE_STREAM_IN_VE = 'Edit Live Stream in VE';
export const CONFIGURE_LIVE_STREAM_IN_VE_CLICKED = 'Configure Live Stream in VE clicked';
export const START_BROADCAST_IN_VE_CLICKED = 'Start broadcast in VE clicked';
export const STOP_BROADCAST_IN_VE_CLICKED = 'Stop broadcast in VE clicked';
export const REMOVE_LIVE_STREAM_KEY_IN_VE = 'Remove live stream key';
export const REMOVE_LIVE_STREAM_URL_IN_VE = 'Remove live stream url';
// Virtual Event Registrant List Icon Click
export const REGISTRANTS_LIST_ICON_CLICKED_IN_VE = 'Registrants list icon clicked in VE';
export const PENDING_REGISTRANT_APPROVED_IN_VE = 'Pending registrant approved in VE';
export const DENIED_REGISTRANT_APPROVED_IN_VE = 'Denied registrant approved in VE';
export const PENDING_REGISTRANT_DENIED_VE = 'Pending registrant denied in VE';
export const APPROVED_REGISTRANT_CANCELLED_IN_VE = 'Approved registrant cancelled in VE';
// Virtual Event Payment Tracker
export const PAYMENT_SET_UP = 'Set up GTW payments';
export const VE_PAYMENT_AMOUNT_SET_UP = 'Set up amount for VE payments';
export const VE_PAYMENT_ADD_AMOUNT = 'VE payments amount added';
export const VE_PAYMENT_ADD_DISCOUNT_CODE = 'Add discount code for VE payments';
export const VE_PAYMENT_UPDATE_DISCOUNT_CODE = 'Discount code for VE payments updated';
// Virtual Event Bulk Registrations
export const BULK_REGISTRATION_CLICKED_IN_VE = 'Bulk registration clicked in VE';
export const DOWNLOADED_SAMPLE_BULK_UPLOAD_FILE_IN_VE = 'Downloaded sample file for bulk registration in VE';
export const BROWSE_FILE_FOR_BULK_REGISTRATION_IN_VE = 'Browse file for bulk registration in VE';
export const UPLOADED_FILE_OF_BULK_REGISTRATION_IN_VE = 'Uploaded file for bulk registration in VE';
// Customize Certificate
export const CUSTOMIZE_CERTIFICATE_CLICKED = 'Customize certificate clicked';
export const SIGNATURE_UPLOADED = 'Signature uploaded';
