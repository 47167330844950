import config from 'appConfig';
import appLogger from 'Utils/logging';
import { get } from 'Utils/objectHelpers';
import { getBearerHeaders } from '../Helpers/getHeaders';
import { fetchJson } from '../Helpers/fetch';
import { DELETE, GET, POST, PUT } from '../Helpers/httpMethods';
import { getContents, getContentsFromIds, updateContentAttributes } from './contentService';
import { getRecordingSize } from '../../Reducks/VideoEditor/selectors';

/**
 * GoToStage Channel Service
 * https://confluence.ops.expertcity.com/pages/viewpage.action?pageId=89875414
 */

const baseUrl = config.g2s.url;

export function getChannels() {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/channels`, options, 'json')
    .catch((res) => (res.status === 404 ? [] : Promise.reject(res)));
}

export function getChannelProfile(contentKey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/channels?query=contentKey:=${contentKey}`, options, 'json');
}

export async function publishContentToChannel(recordingKey, channelKey, attributes) {
  const contents = await getContents([recordingKey]);
  if (contents && contents[0]) {
    const contentId = contents[0].id;
    const options = {
      headers: getBearerHeaders(),
      method: PUT,
      body: JSON.stringify({ contentKeys: [contentId] })
    };

    const requests = [fetchJson(`${baseUrl}/channels/${channelKey}/contents`, options, 'json')];
    if (attributes) {
      requests.push(updateContentAttributes(contentId, attributes));
    }

    const [channelRes] = await Promise.all(requests);
    return channelRes;
  }
  const error = `Unable to publish contents for recordingKey:${recordingKey} and channelKey:${channelKey}`;
  appLogger.error(error);
  throw new Error(error);
}

export function publishVideoToChannel(channelKey, video, attributes) {
  const sizeOfRecording = getRecordingSize(video);
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify({
      channel: { channelKey },
      contents: [
        {
          product: (video.productName || 'g2w').toUpperCase(),
          productRefKey: video.recordingKey,
          title: video.name,
          assetKey: video.assetKey,
          owners: [`USER:${video.accountKey}`],
          size: sizeOfRecording
        }
      ]
    })
  };
  return fetchJson(`${baseUrl}/publish-contents`, options, 'json')
    .then((res) => {
      const contentKey = get(res, (r) => r.success.contents[0].id);
      if (attributes && contentKey) {
        updateContentAttributes(contentKey, attributes);
      }

      return res;
    })
    .catch((error) => {
      if (error.status === 409 && error.body.failure.contents[0].reason.errorCode === 409) {
        // content already exists, need to publish content directly
        return publishContentToChannel(video.recordingKey, channelKey, attributes);
      }
      return Promise.reject(error);
    });
}

/**
 * Create a new channel for the user
 *
 * @param userKey required
 * @param title optional. If not passed in the body the 'channelTitle' is the account owners full name.
 */
export function createChannel(userKey, title) {
  const body = { userKey };
  if (title) {
    body.channelTitle = title;
  }
  const options = {
    headers: getBearerHeaders(),
    method: POST,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/channels`, options, 'json');
}

/**
 * Update a channels profile. All parameters are optional except channelKey.
 *
 * @param channelKey
 * @param isLimitedAudience boolean true for private false for public, defaults to false
 * @param title string
 * @param description string
 * @param logo string url to a hosted image
 * @param banner string url to a hosted image
 * @param theme string color eg red or #FFFFFF
 */
export function updateChannel(channelKey, description, isLimitedAudience, logo, banner, theme, title) {
  const body = {};
  if (title) {
    body.channelTitle = title;
  }
  if (description) {
    body.channelDescription = description;
  }
  if (logo) {
    body.channelLogo = logo;
  }
  if (banner) {
    body.channelBanner = banner;
  }
  if (theme) {
    body.channelTheme = theme;
  }
  if (isLimitedAudience !== undefined && isLimitedAudience !== null) {
    body.isLimitedAudience = isLimitedAudience;
  }

  // if there is nothing to update
  if (Object.keys(body).length === 0) { return Promise.resolve({}); }

  const options = {
    headers: getBearerHeaders(),
    method: PUT,
    body: JSON.stringify(body)
  };
  return fetchJson(`${baseUrl}/channels/${channelKey}`, options, 'json');
}

/**
 * Create a channel and then update its profile. All parameters are optional except userKey.
 *
 * @param userKey
 * @param isLimitedAudience boolean true for private false for public, defaults to false
 * @param title string
 * @param description string
 * @param channelLogo string url to a hosted image
 */
export async function createAndUpdateChannel(userKey, title, description, isLimitedAudience, channelLogo) {
  try {
    const newChannel = await createChannel(userKey, title);
    const channelKey = newChannel.channelKey;
    await updateChannel(channelKey, description, isLimitedAudience, channelLogo);
    return channelKey;
  } catch (error) {
    appLogger.error('channelService.createAndUpdateChannel() failed');
    throw error;
  }
}

export function getUserSettings() {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  return fetchJson(`${baseUrl}/users`, options, 'json');
}

export async function getChannelContents(channelKey) {
  const options = {
    headers: getBearerHeaders(),
    method: GET
  };
  const contentIds = await fetchJson(`${baseUrl}/channels/${channelKey}/contents`, options, 'json');
  const hasContents = contentIds && contentIds.length > 0;
  return hasContents ? getContentsFromIds(contentIds) : [];
}

export function deleteChannel(channelKey) {
  const options = {
    headers: getBearerHeaders(),
    method: DELETE
  };
  return fetchJson(`${baseUrl}/channels/${channelKey}`, options, 'json').then(() => ({ channelKey }));
}
