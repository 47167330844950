import moment from 'moment';
import i18n from 'i18next';
import processingThumbnailImage from 'Components/Card/SharedComponents/Recording/Assets/vdThProcessing.svg';
import defaultThumbnailImage from 'Components/Card/SharedComponents/Recording/Assets/vdThDefault.svg';
import { fetchJson } from '../../../../Api/Helpers/fetch';

export function getRecordingTitle(recording) {
  if (recording.isPublished && recording.content) {
    return recording.content.title;
  }
  return recording.name;
}

export function getFormattedDate(date) {
  if (date) {
    const momentDate = moment(date);
    if (momentDate.isSame(moment(), 'day')) {
      return momentDate.startOf('min').fromNow();
    }

    return momentDate.format(i18n.t('dateFormat.monthAndDayAsStringFormat'));
  }
  return null;
}

export function getCreatedTime(recording) {
  if (recording.createTime) {
    return getFormattedDate(recording.createTime);
  }
  return null;
}

export function getLastModifiedDate(recording) {
  if (recording.isPublished && recording.content) {
    return getFormattedDate(recording.content.modifiedTime);
  }
  return null;
}

export function hmsTimeFormatFromSeconds(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  let timeString;

  if (h > 0) {
    timeString = m < 10 ? `${h}:0` : `${h}:`;
  } else {
    timeString = '';
    if (m === 0) {
      timeString = '0';
    }
  }
  timeString = `${timeString}${m}:`;
  if (s < 10) {
    timeString = `${timeString}0`;
  }
  return `${timeString}${s}`;
}

export function getDuration(recording) {
  if (recording.isPublished
    && recording.content
    && recording.content.duration) {
    return hmsTimeFormatFromSeconds(recording.content.duration);
  }

  return null;
}

export function getDurationInMins(recording) {
  if (recording.isPublished
    && recording.content
    && recording.content.duration) {
    const m = Math.floor(recording.content.duration / 60);
    return m;
  }

  return null;
}

export function isProcessing(recording) {
  let processing = recording.status !== 'COMPLETED';
  if (recording.assetVersionDetails && recording.assetVersionDetails.length) {
    const [latest] = recording.assetVersionDetails.sort((a, b) => b.assetVersion - a.assetVersion);
    processing = latest.status === 'INITIATED';
  }
  return processing;
}

export function getThumbnail(recording) {
  if (recording) {
    if (isProcessing(recording)) {
      return processingThumbnailImage;
    }

    if (recording.isPublished) {
      const content = recording.content;
      if (content.thumbnail && content.thumbnail.cdnLocation) {
        return content.thumbnail.cdnLocation;
      }

      if (content.defaultThumbnail && content.defaultThumbnail.cdnLocation) {
        return content.defaultThumbnail.cdnLocation;
      }
    }

    const thumbnailResource = recording.resources && recording.resources.find(
      (resource) => resource.resourceType === 'thumbnail' && resource.status === 'COMPLETED'
    );

    if (thumbnailResource) {
      const thumbnail = thumbnailResource.attributes.find((attribute) => attribute.name === 'storagelocation');

      if (thumbnail && thumbnail.value) {
        return thumbnail.value;
      }
    }
  }

  return defaultThumbnailImage;
}

export function isAdditional(recording) {
  return recording.status === 'ADDITIONAL';
}

export async function isSubtitleAvailable(recording) {
  if (!recording.transcript) {
    return false;
  }

  try {
    const transcript = await fetchJson(recording.transcript);
    if (transcript.utterances) {
      const cues = Object.values(transcript.utterances);
      return cues.length > 0;
    }
    return false;
  } catch (e) {
    return false;
  }
}
