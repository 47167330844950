import { createSelector } from 'reselect';
import { get } from '../../Utils/objectHelpers';
import { filterRecordingVersionDetailsFromAsset } from '../Recording/selectors';

const formatTimeString = (seconds) => (
  new Date(seconds * 1000).toISOString().slice(11, -1)
);

const getResourceByType = (resources = [], type) => {
  const resource = resources.find((r) => (
    r.resourceType === type && r.status === 'COMPLETED'
  ));

  return resource;
};

const getAttributeFromResource = (resource, name) => {
  const attribute = resource.attributes.find(
    (attr) => attr.name === name
  );

  if (attribute && attribute.value) {
    return attribute.value;
  }

  return '';
};

const getResourceValue = (video, resourceType, attribute) => {
  const resource = getResourceByType(video.resources, resourceType);

  if (resource && attribute) {
    return getAttributeFromResource(resource, attribute);
  }

  return '';
};

const groupByChunk = (inputArray, perChunk = 2) => inputArray.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index / perChunk);

  if (!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [];
  }

  resultArray[chunkIndex].push(item);

  return resultArray;
}, []);

const filterOverlappedEdits = (edits, duration) => {
  if (!edits || !duration) {
    return [];
  }

  let lastEdit;
  let preprocessed = edits.reduce((acc, edit) => {
    if (lastEdit && edit.end < lastEdit.end) {
      return acc;
    }
    lastEdit = edit;
    const result = [...acc, edit.start, edit.end];
    return result;
  }, []);

  preprocessed = edits.length && edits[0].start === 0
    ? preprocessed.slice(1)
    : preprocessed = [0, ...preprocessed];

  preprocessed = edits.length && edits.slice(-1)[0].end === duration
    ? preprocessed.slice(0, -1)
    : preprocessed = [...preprocessed, duration];

  return preprocessed;
};

const getVideoProps = (video) => ({
  accountKey: video.accountKey,
  name: video.name,
  createTime: video.createTime,
  assetKey: video.assetKey,
  assetVersion: video.assetVersion,
  assetVersionDetails: video.assetVersionDetails,
  content: video.content,
  contentId: (video.content && video.content.id) || '',
  isPublished: (video.content && video.content.shareStatus === 'PUBLIC') || false
});

const getEdits = (state) => (
  Object.values(state.videoEditor.edits)
);

const videoDuration = (state) => (
  state.videoEditor.video.duration
);

const assetVersionDetailsSelector = (state) => get(state, (s) => s.videoEditor.video.assetVersionDetails || [], []);

export const getRecordingKey = (video) => (
  getResourceValue(video, 'recording', 'recordingkey')
);

export const getRecordingSize = (video) => (
  getResourceValue(video, 'recording', 'size')
);

const getAudioWaveform = (video) => (
  getResourceValue(video, 'audiowaveform', 'cdnlocation')
);

const getRecordingUrl = (video) => (
  getResourceValue(video, 'recording', 'cdnlocation')
);

const getOriginalRecordingUrlLocation = (video) => (
  getResourceValue(video, 'recording', 'storagelocation')
);

export const getTranscriptUrl = (video) => (
  getResourceValue(video, 'parsed_transcript', 'cdnlocation')
);

export const elapsedTime = createSelector(
  getEdits,
  (edits) => edits.reduce((acc, edit) => acc + (edit.end - edit.start), 0)
);

export const sortEdits = createSelector(
  getEdits,
  (edits) => edits.sort((a, b) => a.start - b.start)
);

export const recordingVersionDetailsSelector = createSelector(
  [assetVersionDetailsSelector],
  filterRecordingVersionDetailsFromAsset
);

export const getClips = createSelector(
  [sortEdits, videoDuration],
  (edits, duration) => {
    const preprocessed = filterOverlappedEdits(edits, duration);
    const groupedByStartEndTimes = groupByChunk(preprocessed, 2);
    const clipsFinal = groupedByStartEndTimes.reduce((clips, [start, end]) => {
      if (start < end) {
        clips.push({
          startTime: formatTimeString(start),
          duration: formatTimeString(end - start)
        });
      }
      return clips;
    }, []);
    return clipsFinal;
  }
);

export const formatVideo = createSelector(
  [getVideoProps, getRecordingKey, getRecordingSize, getRecordingUrl, getTranscriptUrl, getAudioWaveform, getOriginalRecordingUrlLocation],
  (vidProps, recordingKey, recordingSize, src, transcript, waveform, s3Src) => ({ ...vidProps, recordingKey, recordingSize, src, s3Src, transcript, waveform })
);
